import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { CompaniesApi } from '../../api/CompaniesApi';

export const CageRow = ({ ix, cage, tablename = "" }) => {

    const [cageHover, setCageHover] = React.useState(false);
    const [cageDetails, setCageDetails] = React.useState(null);
    const cageLoadingRef = React.useRef(false);

    const onHover = (cage) => {
        cageLoadingRef.current = true;

        if (cageDetails != null) {
            setCageHover(true);
            return;
        };

        setTimeout(function () {
            CompaniesApi.cageView(cage).then((response) => {
                setCageDetails({
                    info: response.data
                });

                if (cageLoadingRef.current) {
                    setCageHover(true);
                }

            }).catch((err) => {

            }).finally();
        }, 300);
    }

    const onOut = () => {
        cageLoadingRef.current = false;
        setCageHover(false);
    }

    return (
        <>
            <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer', color: 'white' }}
                id={"cageCell" + tablename + ix}
                onMouseOver={() => onHover(cage)}
                onMouseOut={() => onOut()}
            >
                {cage}
            </a>
            <Popover placement="right" isOpen={cageHover} target={"cageCell" + tablename + ix}>
                <PopoverHeader>{cageDetails?.info.company_name ?? ""}</PopoverHeader>
                <PopoverBody>
                    <div className="container" style={{ maxWidth: '400px' }}>
                        <div className="row">
                            Status: {cageDetails?.info.cage_status ?? ""}
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </>
    )
}