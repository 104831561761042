import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { CageRow } from '../shared/CageRow';

import { IntFormatter } from '../../utils/NumberFormatter';

export const AdvancedMarketTopApprovedCagesTable = ({ rows, style }) => {

    const topApprovedCagesColumns = [
        {
            name: 'Cage Code',
            selector: row => row.cage_code,
            cell: (row, ix) => <>
                <CageRow ix={ix} cage={row.cage_code} tablename={"topApprovedCage"} />
            </>
        },
        {
            name: 'Name',
            selector: row => row.company_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.company_name}>{row.company_name.substring(0, 12) + ' ...'}</a>
            </>
        },
        {
            name: 'Approvals',
            selector: row => row.approval_count,
            format: row => IntFormatter.format(row.approval_count)
        },
    ]

    return (
        <>
            <DataTable
                columns={topApprovedCagesColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}