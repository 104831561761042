import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { PartsApi } from '../../api/PartsApi';
import { CompaniesApi } from '../../api/CompaniesApi';
import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

import { NiinRow } from '../shared/NiinRow';
import { CageRow } from '../shared/CageRow';

export const AdvancedMarketProcHistTable = ({ rows, style }) => {

    const navigate = useNavigate();
    const routeChange = (niin) => {
        let path = `/usg/parts/search/view/${niin}`;
        navigate(path);
    }

    const [niinId, setNiinId] = React.useState(null);
    const niinIdRef = React.useRef(null);

    const [niinHover, setNiinHover] = React.useState(null);

    const [cageHover, setCageHover] = React.useState(null);

    const onNiinHover = (id, niin) => {
        setNiinId(id);
        niinIdRef.current = id;

        setTimeout(function () {
            if (niinIdRef !== id) console.log(niinId, id);
            PartsApi.view(niin).then((response) => {
                setNiinHover({
                    id: id,
                    info: response.data
                });
            }).catch((err) => {

            }).finally();
        }, 300);
        
    }

    //React.useEffect(() => {
    //    setTimeout(function () {
    //        if (niinId !== id) console.log(niinId, id);
    //        PartsApi.view(niin).then((response) => {
    //            setNiinHover({
    //                id: id,
    //                info: response.data
    //            });
    //        }).catch((err) => {

    //        }).finally();
    //    }, 300);
    //}, [niinId])

    const onNiinOut = () => {
        setNiinId(null);
        niinIdRef.current = null;

        setNiinHover(null);
    }

    const onCageHover = (id, cage) => {
        setTimeout(function () {
            CompaniesApi.cageView(cage).then((response) => {
                setCageHover({
                    id: id,
                    info: response.data
                });
            }).catch((err) => {

            }).finally();
        }, 300);
        
    }

    const onCageOut = () => {
        setCageHover(null);
    }

    const procHistColumns = [
        {
            name: 'Date',
            selector: row => row.award_date,
            sortable: true
        },
        {
            name: 'FSC',
            selector: row => row.fsc,
            sortable: true
        },
        {
            name: 'NIIN',
            selector: row => row.niin,
            cell: (row, ix) => <>
                <NiinRow ix={ix} niin={row.niin} tablename={"ph"} />
            </>
        },
        {
            name: 'Classification',
            selector: row => row.item_classification,
        },
        {
            name: 'Cage Code',
            selector: row => row.cage,
            cell: (row, ix) => <>
                <CageRow ix={ix} cage={row.cage} tablename={"ph"} />
            </>
        },
        {
            name: 'Contract Number',
            selector: row => row.contract_number,
        },
        {
            name: 'Reference',
            selector: row => row.referenced_piid,
        },
        {
            name: 'Quantity',
            selector: row => (row.order_qty ?? "N/A") + " " + (row.unit ?? "?"),
        },
        {
            name: 'Unit Price',
            selector: row => CurrencyFormatter.format(row.netprice),
        },
        {
            name: 'Total Price',
            selector: row => row.total_price,
            format: row => CurrencyFormatter.format(row.total_price),
            sortable: true
        },
        {
            name: 'Price Reasonableness',
            selector: row => row.price_reason
        }
    ]

    return (
        <>
            <DataTable
                columns={procHistColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />

            {
                //niinHover != null &&
                //<div class="modal" tabindex="-1" show={true}>
                //    <div class="modal-dialog">
                //        <div class="modal-content">
                //            <div class="modal-header">
                //                <h5 class="modal-title">Modal title</h5>
                //                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                //                    <span aria-hidden="true">&times;</span>
                //                </button>
                //            </div>
                //            <div class="modal-body">
                //                <p>Modal body text goes here.</p>
                //            </div>
                //            <div class="modal-footer">
                //                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                //                <button type="button" class="btn btn-primary">Save changes</button>
                //            </div>
                //        </div>
                //    </div>
                //</div>
                //<Popover placement="right" isOpen={niinHover !== null} target={niinHover.id}>
                //    <PopoverHeader>{niinHover.info.nomenclature}</PopoverHeader>
                //    <PopoverBody>
                //        <div className="container" style={{ maxWidth: '400px' }}>
                //            <div className="row">
                //                Fsc: { niinHover.info.fsc }
                //            </div>
                //        </div>
                //    </PopoverBody>
                //</Popover>
            }
            {
                cageHover != null &&
                //<div class="modal" tabindex="-1" show={true}>
                //    <div class="modal-dialog">
                //        <div class="modal-content">
                //            <div class="modal-header">
                //                <h5 class="modal-title">Modal title</h5>
                //                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                //                    <span aria-hidden="true">&times;</span>
                //                </button>
                //            </div>
                //            <div class="modal-body">
                //                <p>Modal body text goes here.</p>
                //            </div>
                //            <div class="modal-footer">
                //                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                //                <button type="button" class="btn btn-primary">Save changes</button>
                //            </div>
                //        </div>
                //    </div>
                //</div>
                <Popover placement="right" isOpen={cageHover !== null} target={cageHover.id}>
                    <PopoverHeader>{cageHover.info.company_name}</PopoverHeader>
                    <PopoverBody>
                        <div className="container" style={{ maxWidth: '400px' }}>
                            <div className="row">
                                Status: {cageHover.info.cage_status}
                            </div>
                        </div>
                    </PopoverBody>
                </Popover>
            }
        </>
    );
}